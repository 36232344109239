<template>
  <div class="hello">
    hello world
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      
    }
  }
}
</script>
 
<style scoped>
 .hello{width: 100%;}
 
</style>
